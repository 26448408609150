import { values } from "ramda"
import { getLocalizedOption } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const NONE = {
  value: "NONE",
  token: localizationKey("None"),
}

export const READ_ONLY = {
  value: "READ_ONLY",
  token: localizationKey("Read Only"),
}

export const EDITABLE = {
  value: "EDITABLE",
  token: localizationKey("Editable"),
}

export const technicianPermissions = {
  NONE,
  READ_ONLY,
  EDITABLE,
}

export const technicianPermissionOptions = () => values(technicianPermissions).map(getLocalizedOption)

export const WRITE_ONLY = {
  value: "WRITE_ONLY",
  token: localizationKey("Write Only"),
}

export const READ_WRITE = {
  value: "READ_WRITE",
  token: localizationKey("Read/Write"),
}

export const scriptPermissions = {
  NONE,
  READ_ONLY,
  WRITE_ONLY,
  READ_WRITE,
}

export const scriptPermissionOptions = () => values(scriptPermissions).map(getLocalizedOption)
