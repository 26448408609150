// prettier-ignore
import { localized } from "js/includes/common/utils";

const operatorOptions = {
  contains: { value: "CONTAINS", label: localized("contains") },
  contains_any: { value: "CONTAINS_ANY", label: localized("contains any") },
  contains_none: { value: "CONTAINS_NONE", label: localized("contains none") },

  equals: { value: "EQUALS", label: localized("equals") },
  not_equals: { value: "NOT_EQUALS", label: localized("does not equal") },

  greater_than: { value: "GREATER_THAN", label: localized("greater than") },
  less_than: { value: "LESS_THAN", label: localized("less than") },
  greater_or_equal_than: {
    value: "GREATER_OR_EQUAL_THAN",
    label: localized("greater than or equal to"),
  },
  less_or_equal_than: { value: "LESS_OR_EQUAL_THAN", label: localized("less than or equal to") },

  on: { value: "EQUALS", label: localized("on") },
  not_on: { value: "NOT_EQUALS", label: localized("not on") },
  after: { value: "GREATER_THAN", label: localized("after") },
  before: { value: "LESS_THAN", label: localized("before") },
  on_or_after: { value: "GREATER_OR_EQUAL_THAN", label: localized("on or after") },
  on_or_before: { value: "LESS_OR_EQUAL_THAN", label: localized("on or before") },

  is_not_null: { value: "IS_NOT_NULL", label: localized("Exists") },
  is_null: { value: "IS_NULL", label: localized("Doesn't exist") },
}

export const getOperatorOption = operator => operatorOptions[operator]

export const getOperatorOptions = operators => operators.map(getOperatorOption)

// prettier-ignore
export const getFieldTypeOperators = type => {
  switch (type) {
    case "NODE_MULTI_SELECT":
    case "CLIENT_MULTI_SELECT":
    case "CLIENT_LOCATION_MULTI_SELECT":
    case "MULTI_SELECT":
      return getOperatorOptions([
        "contains",
        "contains_any",
        "contains_none",
        "is_not_null",
        "is_null",
      ])
    case "NODE_DROPDOWN":
    case "CLIENT_DROPDOWN":
    case "CLIENT_LOCATION_DROPDOWN":
    case "DROPDOWN":
      return getOperatorOptions([
        "equals",
        "not_equals",
        "is_not_null",
        "is_null",
      ])
    case "CHECKBOX":
      return getOperatorOptions([
        "equals",
        "is_not_null",
        "is_null",
      ])
    case "TEXT":
    case "TEXT_MULTILINE":
    case "WYSIWYG":
      return getOperatorOptions([
        "contains",
        "contains_none",
        "equals",
        "not_equals",
        "is_not_null",
        "is_null",
      ])
    case "ATTACHMENT":
    case "TEXT_ENCRYPTED":
      return getOperatorOptions([
        "is_not_null",
        "is_null",
      ])
    case "NUMERIC":
    case "DECIMAL":
      return getOperatorOptions([
        "equals",
        "not_equals",
        "greater_than",
        "less_than",
        "greater_or_equal_than",
        "less_or_equal_than",
        "is_not_null",
        "is_null",
      ])
    case "DATE":
    case "TIME":
    case "DATE_TIME":
      return getOperatorOptions([
        "on",
        "not_on",
        "after",
        "before",
        "on_or_after",
        "on_or_before",
        "is_not_null",
        "is_null",
      ])
    default:
      return null
  }
}
