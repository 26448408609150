import fastDeepEqual from "fast-deep-equal"
import moment from "moment"
import { match, reduce } from "ramda"

import { localized } from "js/includes/common/utils"

export const ENABLE_CLICK_ON_PORTAL_CLASS_NAME = "portal-in-custom-fields-form"

export const getAdvancedSettingsKeyValuePair = (options = [], values = {}) => {
  return reduce(
    (acc, option) => {
      acc[option.id] = option.getInitialValue?.(values?.[option.id])
      return acc
    },
    {},
    options,
  )
}

export const getAdvancedSettingsValidation = (options = [], values = {}) => {
  return reduce(
    (acc, option) => {
      if (option.validate) acc[option.id] = option.validate
      return acc
    },
    {},
    options,
  )
}

export const getAttributeScope = (entity, attributeScope) =>
  attributeScope ? (entity + "_" + attributeScope).toUpperCase() : null

export const formatCustomFieldForServer = (field, entity) => ({
  ...(entity === "client" && field.id && { id: field.id }),
  name: field.label,
  fieldName: field.name,
  attributeType: field.type,
  description: field.description,
  defaultValue: field.defaultValue,
  technicianOption: "OPTIONAL",
  scriptPermission: field.scriptPermission,
  apiPermission: field.apiPermission,
  technicianPermission: field.technicianPermission,
  definitionScope: field.definitionScope,

  content: {
    values: field.options,
    required: field.required,
    footerText: field.footerText,
    tooltipText: field.tooltipText,
    advancedSettings: field.advancedSettings,
  },

  attributeScope: getAttributeScope(entity, field.attributeScope),
})

export const mapFieldOrUIElementToServerItem = items =>
  items.map(item => ({
    id: item.id,
    name: item.id ? null : item.name,
    itemType: item.id ? "ATTRIBUTE" : "UI_ELEMENT",
    uiElementUid: item.uiElementUid ?? null,
    uiElementType: item.uiElementType ?? null,
    uiElementValue: item.uiElementValue ?? null,
    ...(item.uiElementCreateTime && { uiElementCreateTime: item.uiElementCreateTime }),
    ...(item.uiElementUpdateTime && { uiElementUpdateTime: item.uiElementUpdateTime }),
  }))

export const searchableDropdownCommonProps = {
  dropdownClassName: ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
  width: "100%",
  minHeight: "32px",
  borderRadius: "2px",
  openOnFocus: true,
}

export const hasEditableCustomFields = fields =>
  fields.some(field => {
    return field?.technicianPermission === "EDITABLE"
  })

export const hasCustomFields = fields =>
  fields.some(field => {
    return field?.technicianPermission
  })

const removeTrailingSlash = url => url.replace(/\/+$/, "")

export const filterEditableFieldValues = (fields, values, initialValues) => {
  const formatValueForURLType = value => {
    const urlValue = value.value ? removeTrailingSlash(new URL(value.value).href) : null
    return { ...value, value: urlValue }
  }

  return reduce(
    (acc, field) => {
      if (field.technicianPermission === "EDITABLE") {
        const oldValue = initialValues?.[field.id]
        const newValue = field.type === "URL" ? formatValueForURLType(values[field.id]) : values[field.id]

        const hasChanged = !fastDeepEqual(oldValue, newValue)

        if (hasChanged) {
          acc.push(formatCustomFieldValueForServer(newValue))
        }
      }
      return acc
    },
    [],
    fields,
  )
}

export const formatCustomFieldValueForServer = ({ id, attributeId, value }) => ({
  id,
  attributeId,
  value,
})

export const disabledDays = advancedSettings => {
  const { type, selected } = advancedSettings?.dateFilters || {}
  switch (type) {
    case "RANGE":
      return {
        before: moment(selected?.[0]?.split(" ")[0]).toDate(),
        after: moment(selected?.[1]?.split(" ")[0]).toDate(),
      }
    case "FUTURE_DATES_ONLY":
      return {
        before: moment()
          .add(1, "days")
          .toDate(),
      }
    case "PAST_DATES_ONLY":
      return {
        after: moment()
          .subtract(1, "days")
          .toDate(),
      }
    case "EXCLUDE":
      return selected?.map(date => moment(date.split(" ")[0]).toDate())
    default:
      return null
  }
}

export const isValidDate = (datetime, advancedSettings) => {
  const today = moment()

  if (datetime === null) return true

  switch (advancedSettings?.dateFilters?.type) {
    case "EXCLUDE":
      return !advancedSettings?.dateFilters?.selected?.includes(
        moment(datetime)
          .startOf("day")
          .format("YYYY-MM-DD Z"),
      )
    case "PAST_DATES_ONLY":
      return moment(datetime).isSameOrBefore(today)
    case "FUTURE_DATES_ONLY":
      return moment(datetime).isSameOrAfter(today)
    case "RANGE":
      const [start, end] = advancedSettings?.dateFilters?.selected ?? []

      return (
        moment(datetime).isSameOrAfter(moment(start, "YYYY-MM-DD Z").startOf("day")) &&
        moment(datetime).isSameOrBefore(moment(end, "YYYY-MM-DD Z").endOf("day"))
      )
    default:
      return true
  }
}

export const parseDocumentationPath = (dashboardPath, locationPath) => {
  const matchPath = RegExp(`^${dashboardPath}/([a-z]+)(/[0-9]+){0,1}(/[0-9]+)*(/archived)*`, "i")
  const [fullPath, tabNamePath, templateIdPath, documentIdPath, archivedPath] = match(matchPath, locationPath)
  const tabName = tabNamePath ?? ""
  const templateId = parseInt(templateIdPath?.replace("/", ""), 10)
  const documentId = parseInt(documentIdPath?.replace("/", ""), 10)
  const templatePath = tabName && templateId ? `${dashboardPath}/${tabName}/${templateId}` : ""
  const isArchived = !!archivedPath

  return {
    fullPath,
    tabName,
    templateId,
    documentId,
    templatePath,
    isArchived,
  }
}

export const definitionScopes = () => [
  { value: "NODE", label: localized("Device") },
  { value: "LOCATION", label: localized("Location") },
  { value: "ORGANIZATION", label: localized("Organization") },
]

export const canUserSaveCustomFieldsForm = (fields, values) =>
  !fields.some(field => {
    return field.required && field.technicianPermission !== "EDITABLE" && !values[field.id]
  })

export const handleFormFieldChange = ({ onChange, id, isHookForm = false }) => {
  return value => {
    if (isHookForm) {
      onChange(value)
    } else {
      onChange({ [id]: value })
    }
  }
}

export const handleFormFieldBlur = ({ onBlur = () => {}, id, isHookForm = false, validateFormFields }) => {
  if (isHookForm) {
    return onBlur
  } else {
    validateFormFields?.([id])
  }
}
